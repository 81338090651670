import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import {
  setExchangeRate,
  setReferralReward,
  setTokenAllowance,
  setTokenBalance,
  setTokenSold,
  setTokenToSale,
  setTotalSupply,
  setUsdtBalance,
} from "../store/wallet/wallet.slice";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import { contracts } from "../config";
// import { WalletActionButton } from "@tronweb3/tronwallet-adapter-react-ui";
import {
  WalletActionButton,
  WalletModalProvider,
} from "@tronweb3/tronwallet-adapter-react-ui";
import Platfairlogo from "../assets/imgs/logo_platfair_hd.png";

function Header({
  setConnectedAddress,
  setIsWalletConnected,
  setIsReferralAllowed,
}) {
  const [scrollY, setScrollY] = useState(0); // Initial background color

  const [menuOpen, setMobileMenu] = useState(false);

  const menuClickHandler = function(){
    if(menuOpen){ setMobileMenu(false);}
    
    else { setMobileMenu(true);}
  }

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { address } = useWallet();
  useEffect(() => {
    if (address) {
      setConnectedAddress(address);
      setIsWalletConnected(true);
      haveUserMinted(contracts.nftContract10Levels, address);
    }
    if (address == null) {
      setConnectedAddress("");
      setIsWalletConnected(false);
      setIsReferralAllowed(false);
    }
    console.log("address test:" + address);
  }, [address]);
  const handleScroll = () => {
    setScrollY(window?.scrollY);
  };
  async function haveUserMinted(nftContractAddress, connectedAddress) {
    try {
      const tron = window.tron;
      const tronWeb = tron?.tronWeb;

      let abi_With_balanceOf = contracts.nftContractABI;

      let contract = await tronWeb?.contract(
        abi_With_balanceOf,
        nftContractAddress
      );
      let result = await contract
        .isReferralAllowed(connectedAddress.toString())
        .call();
      // console.log("Minted: " + result);
      if (contracts.nftContract10Levels == nftContractAddress) {
        console.log("10Levels Minted: " + result);
      }
      if (result) {
        setIsReferralAllowed(true);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      // showNotification("Error while fetching nft data", "error");
      console.log(error);
      return false;
    }
  }
  useEffect(() => {
    // Add a scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Clean up the scroll event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className="header content"
      style={{
        backgroundColor: scrollY > 90 ? "#000000" : "#261faa",
      }}
    >
      <Container className="d-flex justify-content-between px-3 px-md-5 align-items-center" fluid>
        <div className="d-flex align-items-center">
          <h2
            className="logo mb-0"
            onClick={() => {
              navigate("/");
            }}
          >
            <a href="https://platfair.ai/">
              <img
                src={Platfairlogo}
                alt="hero"
                draggable="false"
                className="img-responsive"
              />
            </a>
          </h2>
        </div>


        {/* <div className="menu_header" >
          <div className={menuOpen === true ? 'burger-menu menu-open' : 'burger-menu'} onClick={menuClickHandler}>
            <svg width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 18L20 18" stroke="#ffffff" strokeWidth="2" strokeLinecap="round"></path>
              <path d="M4 12L20 12" stroke="#ffffff" strokeWidth="2" strokeLinecap="round"></path>
              <path d="M4 6L20 6" stroke="#ffffff" strokeWidth="2" strokeLinecap="round"></path>
            </svg>
          </div>
          <ul className="nav">
            <li><a href="/dashboard">Dashboard</a></li>
            <li><a href="/assets">Assets Page</a></li>
            <li className="tokengate">Tokengate
              <ul>
                <li><a href="tokengate">Video</a></li>
                <li><a href="allnews">Newsletter</a></li>
              </ul>
            </li>
          </ul>
        </div> */}


        <WalletModalProvider>
          <WalletActionButton
            className="btn btn-primary"
            // style={{ width: "100%" }}
          ></WalletActionButton>
        </WalletModalProvider>{" "}
      </Container>
    </div>
  );
}

export default Header;
