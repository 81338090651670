import React from "react";

import { TronLinkAdapter } from "@tronweb3/tronwallet-adapters";
import { WalletConnectAdapter } from "@tronweb3/tronwallet-adapter-walletconnect";
import {
  Adapter,
  WalletDisconnectedError,
  WalletError,
  WalletNotFoundError,
} from "@tronweb3/tronwallet-abstract-adapter";
import { showNotification } from "./utils";
import {
  WalletProvider,
  useWallet,
} from "@tronweb3/tronwallet-adapter-react-hooks";
import Minting from "./minting";
import reportWebVitals from "./reportWebVitals";
import "./assets/scss/custom.scss";
import { store } from "./store/store";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useMemo, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import "@tronweb3/tronwallet-adapter-react-ui/style.css";
import { contracts } from "./config";
import Dashboard from "./dashboard";
import Tokengate from "./tokengate";
import AssetsPage from "./assets";
import Header from "./components/Header";
import MainApp from "./mainapp";
export default function App() {
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [connectedAddress, setConnectedAddress] = useState("");
  //const [isApproved10LevelRef, setisApproved10LevelRef] = useState(false);

  const [isReferralAllowed, setIsReferralAllowed] = useState(false);

  function onError(e) {
    if (e instanceof WalletNotFoundError) {
      showNotification(e.message, "error");
    } else if (e instanceof WalletDisconnectedError) {
      showNotification(e.message, "error");
    } else showNotification(e.message, "error");
  }

  const adapters = useMemo(function () {
    const tronLinkAdapter = new TronLinkAdapter();
    const walletConnectAdapter = new WalletConnectAdapter({
      network: "Mainnet",
      options: {
        relayUrl: "wss://relay.walletconnect.com",
        // example WC app project ID
        projectId: "a18c7e248b10560477a47c190e778e2a",
        metadata: {
          name: "U House Private sale",
          description: "U House Private sale",
          url: "",
          icons: [""],
        },
      },
      web3ModalConfig: {
        themeMode: "dark",
        themeVariables: {
          "--w3m-z-index": "1000",
        },
      },
    });
    // setwalletConnectAdapter(walletConnectAdapter);
    return [tronLinkAdapter, walletConnectAdapter];
  }, []);
  useEffect(() => {
    // if (!address) return;
    // tronWeb.setAddress(address);

    adapters.forEach((adapter) => {
      adapter.on("readyStateChanged", async () => {
        console.log("Ready State: " + adapter.address);
        // console.log("Address: " + address);
      });
      adapter.on("connect", async (address) => {
        console.log("wallet connected");
        setIsWalletConnected(true);

        setConnectedAddress(address);
        // setConnectedAddress("TMy4Gh11ZnDCPEkVAwA62UqwaX9Rb65Doc");
        try {
          //dispatch(setUserDataExists(true));

          console.log(address);
          await haveUserMinted(contracts.nftContract10Levels, address);
        } catch (error) {
          console.log(error);
        }
      });
      adapter.on("accountsChanged", async (data) => {
        // debugger;
        // dispatch(setUserDataExists(false));
        //const isUserExists = await isUserExist(data?.toLowerCase());
        //  debugger;
        if (true) {
          console.log("account changed");
          setIsReferralAllowed(false);
          //dispatch(setUserDataExists(true));
          setIsWalletConnected(true);
          setConnectedAddress(data);
          // setConnectedAddress("TMy4Gh11ZnDCPEkVAwA62UqwaX9Rb65Doc");
          try {
            console.log(data);
            await haveUserMinted(contracts.nftContract10Levels, data);
          } catch (error) {
            console.log(error);
          }
        }
      });

      adapter.on("disconnect", () => {
        try {
          // dispatch(setUserDataExists(false));
          setIsWalletConnected(false);
          setConnectedAddress("");
          setIsReferralAllowed(false);
        } catch (error) {
          console.log(error);
        }
      });

      return () => {
        adapter.removeAllListeners();
      };
    });

    return () => {
      adapters.forEach((adapter) => {
        adapter.removeAllListeners();
      });
    };
  }, [adapters]);

  async function haveUserMinted(nftContractAddress, connectedAddress) {
    try {
      const tron = window.tron;
      const tronWeb = tron?.tronWeb;

      let abi_With_balanceOf = contracts.nftContractABI;

      let contract = await tronWeb?.contract(
        abi_With_balanceOf,
        nftContractAddress
      );
      let result = await contract
        .isReferralAllowed(connectedAddress.toString())
        .call();
      // console.log("Minted: " + result);
      if (contracts.nftContract10Levels == nftContractAddress) {
        console.log("10Levels Minted: " + result);
      }
      if (result) {
        setIsReferralAllowed(true);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      // showNotification("Error while fetching nft data", "error");
      console.log(error);
      return false;
    }
  }
  return (
    <>
      <WalletProvider
        onError={onError}
        autoConnect={true}
        disableAutoConnectOnLoad={false}
        adapters={adapters}
      >
        <MainApp
          setIsWalletConnected={setIsWalletConnected}
          setConnectedAddress={setConnectedAddress}
          setIsReferralAllowed={setIsReferralAllowed}
          isWalletConnected={isWalletConnected}
          connectedAddress={connectedAddress}
          isReferralAllowed={isReferralAllowed}
        />
        <ToastContainer />
      </WalletProvider>
    </>
  );
}
