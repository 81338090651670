import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Minting from "./minting";
import Dashboard from "./dashboard";
import Tokengate from "./tokengate";
import AssetsPage from "./assets";
import Header from "./components/Header";
import NewsViewer from "./newsviewer";
import AllNews from "./allnews";
import VideoViewer from "./videoviewer";

export default function MainApp({
  setIsWalletConnected,
  setConnectedAddress,
  setIsReferralAllowed,
  isWalletConnected,
  connectedAddress,
  isReferralAllowed,
}) {
  return (
    <>
      <BrowserRouter>
        <Header
          setIsWalletConnected={setIsWalletConnected}
          setConnectedAddress={setConnectedAddress}
          setIsReferralAllowed={setIsReferralAllowed}
        />
        <Routes>
           {/*<Route
            path="/"
            element={
              <Minting
                isReferralAllowed={isReferralAllowed}
                connectedAddress={connectedAddress}
                isWalletConnected={isWalletConnected}
              />
            }
          /> */}
          <Route
            path="/ref/:referer"
            element={
              <Minting
                isReferralAllowed={isReferralAllowed}
                connectedAddress={connectedAddress}
                isWalletConnected={isWalletConnected}
              />
            }
          />
          <Route
            path="/"
            element={
              <Dashboard
                connectedAddress={connectedAddress}
                isWalletConnected={isWalletConnected}
              />
            }
          />
          {/* <Route
            path="/tokengate"
            element={
              <Tokengate
                connectedAddress={connectedAddress}
                isWalletConnected={isWalletConnected}
              />
            }
          />
          <Route
            path="/assets"
            element={
              <AssetsPage
                connectedAddress={connectedAddress}
                isWalletConnected={isWalletConnected}
              />
            }
          />
          <Route
            path="/newsviewer/:newsId"
            element={
              <NewsViewer
                connectedAddress={connectedAddress}
                isWalletConnected={isWalletConnected}
              />
            }
          />

          <Route
           path="/videoviewer/:videoId"
           element={
             <VideoViewer
               connectedAddress={connectedAddress}
               isWalletConnected={isWalletConnected}
             />
           }
         />
        
         <Route
            path="/allnews"
            element={
              <AllNews
                connectedAddress={connectedAddress}
                isWalletConnected={isWalletConnected}
              />
            }
          /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}
