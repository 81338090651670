import { useMemo, useEffect, useState } from "react";

import { useParams } from "react-router";
import { showNotification } from "./utils";
import { useDispatch } from "react-redux";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useLocation } from "react-router-dom";
import { contracts } from "./config";

function Minting({ isReferralAllowed, connectedAddress, isWalletConnected }) {
  const [totalCount, setTotalCount] = useState(0);
  const [allCollections, setAllCollections] = useState([
    { name: "Leave This Empty", pricePerToken: 0, isApproved: 0 },
    { name: "Collection 1", pricePerToken: 150, isApproved: 0 },
    { name: "Collection 2", pricePerToken: 150, isApproved: 0 },
    { name: "Collection 3", pricePerToken: 150, isApproved: 0 },
    { name: "Collection 4", pricePerToken: 150, isApproved: 0 },
    { name: "Collection 5", pricePerToken: 150, isApproved: 0 },
    { name: "Collection 6", pricePerToken: 150, isApproved: 0 },
    { name: "Collection 7", pricePerToken: 150, isApproved: 0 },
    { name: "Collection 8", pricePerToken: 150, isApproved: 0 },
    { name: "Collection 9", pricePerToken: 150, isApproved: 0 },
    { name: "Collection 10", pricePerToken: 150, isApproved: 0 },
    { name: "Collection 11", pricePerToken: 150, isApproved: 0 },
    { name: "Collection 12", pricePerToken: 150, isApproved: 0 },
  ]);

  let { referer } = useParams();
  console.log("Referer: " + referer);
  if (referer == null) {
    referer = "";
  }
  //let pricePerToken10Levels = 150;

  const location = useLocation();
  const currentLocation = window.location.host;
  const dispatch = useDispatch();
  const windowInstance = window;
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setTotalCount(inputValue);
  };
  // useEffect(() => {
  //   checkTribeSupply(1);
  // }, []);

  async function checkTribeSupply(collectionId) {
    try {
      const tron = window.tron;
      const tronWeb = tron?.tronWeb;

      let abi_With_balanceOf = contracts.nftContractABI;

      let contract = await tronWeb?.contract(
        abi_With_balanceOf,
        contracts.nftContract10Levels
      );
      console.log("Conencted Address: " + connectedAddress);
      let collectionInfo = await contract.allCollections(collectionId).call();
      console.log("collectionSupply: " + collectionInfo[4]);
      let collectionSupply = collectionInfo[4];
      let maxSupply = collectionInfo[5];
      console.log("maxSupply: " + maxSupply);
      console.log("totalCount: " + totalCount);
      if (
        parseInt(collectionSupply) + parseInt(totalCount) >
        parseInt(maxSupply)
      ) {
        return false;
      }
      return true;
    } catch (e) {
      console.log(e);
      return true;
    }
  }
  async function mintNft(nftContractAddress, referer, collectionId) {
    try {
      const tron = window.tron;
      const tronWeb1 = tron?.tronWeb;
      if (connectedAddress == "") {
        showNotification("Please connect wallet first", "error");
        return null;
      }
      if (referer == "") {
        referer = connectedAddress;
      }
      if (!(await checkTribeSupply(collectionId))) {
        showNotification("Not Enough Nfts Left in this Tribe.", "error");
        return;
      }
      //let amount = totalCount * pricePerToken;
      // const amountInSun = tronWeb1.toSun(amount, 6);

      const parameter = [
        {
          type: "address",
          value: referer,
        },
        {
          type: "uint256",
          value: totalCount,
        },
        {
          type: "uint256",
          value: collectionId,
        },
      ];
      console.log(
        "parameters: " +
          JSON.stringify(parameter) +
          "  / connectedAddress: " +
          connectedAddress
      );

      //  let estimate = await tronWeb1.transactionBuilder.estimateEnergy(
      //    nftContractAddress,
      //    "publicMint(address,uint256)",
      //    { feeLimit: 150000000 },
      //    parameter
      //   );
      //
      //   if (estimate.toString().includes("REVERT")) {
      //     console.log(estimate);
      //     return;
      //   }

      const tx = await tronWeb1?.transactionBuilder.triggerSmartContract(
        nftContractAddress,
        "publicMint(address,uint256,uint256)",
        { feeLimit: 1000000000 },
        parameter
      );
      console.log(tx);

      const res = await tronWeb1?.trx.sign(tx.transaction);
      console.log(tx.transaction);
      // const result = await tronWeb.trx.sendRawTransaction(res);
      const senttx = await tronWeb1?.trx.sendRawTransaction(res);
      console.log("senttx: " + senttx);

      if (nftContractAddress == contracts.nftContract10Levels) {
        let tempAllCollections = [...allCollections];
        tempAllCollections[collectionId].isApproved = 0;
        setAllCollections(tempAllCollections);
      }
      showNotification(`Nft Minted`, "success");
    } catch (e) {
      console.log(e);
    }
  }
  const approveHandler = async (collectionId) => {
    try {
      let contractAddress = contracts.nftContract10Levels;
      const tron = window.tron;
      const tronWeb1 = tron?.tronWeb;
      if (totalCount <= 0) {
        showNotification("Select Quantity", "error");
        return;
      }
      if (connectedAddress == "") {
        showNotification("Please connect wallet first", "error");
        return null;
      }
      if (!(await checkTribeSupply(collectionId))) {
        showNotification("Not Enough Nfts Left in this Tribe.", "error");
        return;
      }
      let amount = totalCount * allCollections[collectionId].pricePerToken;

      const amountInSun = tronWeb1?.toSun(amount, 6);
      const parameters = [
        {
          type: "address",
          value: contractAddress,
        },
        {
          type: "uint256",
          value: parseInt(amountInSun),
        },
      ];
      console.log(
        "amountInSun: " + amountInSun + " - nftContract: " + contractAddress
      );
      const tx = await tronWeb1?.transactionBuilder.triggerSmartContract(
        contracts.usdtContract,
        "approve(address,uint256)",
        { feeLimit: 1000000000, callValue: 0 },
        parameters,
        tronWeb1.address.toHex(connectedAddress)
      );
      console.log("amountInSun", amountInSun);

      const res = await tronWeb1.trx.sign(tx.transaction);

      const result = await tronWeb1.trx.sendRawTransaction(res);
      if (result.result) {
        // alert("Success");
      }
      // if (contractAddress == contracts.nftContract10Levels) {
      //   setisApproved10LevelRef(true);
      // }

      let tempAllCollections = [...allCollections];
      tempAllCollections[collectionId].isApproved = 1;

      setAllCollections(tempAllCollections);

      showNotification(`Approved ${amount} USDT`, "success");
    } catch (e) {
      console.log(e);
      showNotification("Something went wrong", "error");
    }
  };
  //console.log(address);

  return (
    <div className="hero-page">
      {/* <Header /> */}

      <div className="imagem_nft">
        <div className="box2">
          <h1>
            <span className="sec-branco">Mint your Adventure</span>
            <span className="sec-colorido"> NFT</span>
          </h1>
          <div className="sec-pequeno u-meta ">
            <br></br>
            <h1 className="sec-pequeno">Your Referral Link</h1>
            {isReferralAllowed && isWalletConnected && (
              <h1 className="sec-pequeno">
                {currentLocation}/ref/{connectedAddress}
              </h1>
            )}
            {!isReferralAllowed && isWalletConnected && (
              <h1 className="sec-pequeno">You need to mint atleast 1 Nft.</h1>
            )}
            {!isWalletConnected && (
              <h1 className="sec-pequeno">Connect Your Wallet.</h1>
            )}
          </div>
        </div>

        <div className="wrap wrapWidth flex aic jc">
          <div className="_block flex">
            <div className="block-left-side flex">
              <div className="box">
                {/*<div className="box2">*/}

                {/*  cuantos foram vendidos
                <div className="numb-sec flex aic jc">
                  <h3 className="numbs">13 / 10,000 NFTs Sold</h3>
                </div>
                */}

                <div className="slug">
                  <span className="dot"></span>
                  <h2 className="slug-txt">Select your quantity:</h2>
                  <span className="dot"></span>
                </div>

                <div className="mint-price-sec flex aic">
                  {/*<div className="eth-val cleanbtn">10 USDT</div>*/}

                  <input
                    type="number"
                    className="e-numb cleanbtn"
                    placeholder="Quantity"
                    value={totalCount}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="butoes_h">
                  <br></br>
                  <span className="bold_butoes">Premium NFT</span>- Price: 150
                  USDT
                </div>

                <div className="actions flex flex-col">
                  {allCollections.map((single, i) => (
                    <>
                      {single.pricePerToken != 0 && (
                        <>
                          {single.isApproved === 1 ? (
                            <button
                              key={i}
                              className="btn-mint button cleanbtn"
                              onClick={() => {
                                mintNft(
                                  contracts.nftContract10Levels,
                                  referer,
                                  i
                                );
                              }}
                            >
                              Step 2 of 2 → Mint NFT ({single.name})
                            </button>
                          ) : (
                            <button
                              className="btn-mint button cleanbtn"
                              onClick={() => {
                                approveHandler(i);
                              }}
                            >
                              Step 1 of 2 → Approve Your Wallet {single.name}
                            </button>
                          )}
                        </>
                      )}
                    </>
                  ))}
                  <a href="https://platfair.ai/tribes/">
                    <button className="but3">
                      Discover our other NFT Tribes{" "}
                    </button>
                  </a>
                </div>
              </div>
              {/*</div>*/}
            </div>

            <div className="block-right-side flex">
              <p className="texto_tribu">
                <span className="titulo_texto_tribu">
                  {" "}
                  Tribe of Adventure ⚔️
                </span>
                <br></br>
                <br></br>
                Venture into the vastness of the digital unknown. This NFT is a
                testament to bravery, urging you to explore where few dare.
                <br></br>
                <br></br>
                Drawing inspiration from legendary explorers, let the spirit of
                adventure guide your crypto journey.
                <br></br>
                <br></br>
                Step boldly, acquire this NFT, and redefine boundaries in the
                ever-evolving digital frontier.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
}
export default Minting;
