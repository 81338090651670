import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";

import Appstorelogo from "./assets/imgs/appstore.png";
import Playstorelogo from "./assets/imgs/playstore.png";

import { isUserExist, showNotification } from "./utils";

import { useMemo, useEffect, useState } from "react";
import { contracts } from "./config";
import { useParams } from "react-router";
import "./assets/css/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWallet,
  faDollarSign,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import {
  WalletProvider,
  useWallet,
} from "@tronweb3/tronwallet-adapter-react-hooks";

export default function Dashboard() {
  //let connectedAddress = "TMy4Gh11ZnDCPEkVAwA62UqwaX9Rb65Doc";
  // const [isWalletConnected, setisWalletConnected] = useState(false);
  const currentLocation = window.location.host;
  const [isWalletConnected, setisWalletConnected] = useState(true);
  const { address } = useWallet();
  const [connectedAddress, setconnectedAddress] = useState(address);
  const [totalNftMintedThroughReferral, settotalNftMintedThroughReferral] =
    useState(0);
  const [totalUsdEarned, settotalUsdEarned] = useState(0);
  const [userBalance, setuserBalance] = useState();
  async function fetchData(connectedAddress) {
    try {
      const tron = window.tron;
      const tronWeb = tron?.tronWeb;

      let abi_With_balanceOf = contracts.nftContractABI;

      let contract = await tronWeb?.contract(
        abi_With_balanceOf,
        contracts.nftContract10Levels
      );
      console.log("Conencted Address: " + connectedAddress);
      let totalNftMintedThroughRef = await contract
        .totalNftMintedThroughRef(connectedAddress?.toString())
        .call();
      console.log("totalNftMintedThroughRef: " + totalNftMintedThroughRef);
      settotalNftMintedThroughReferral(parseInt(totalNftMintedThroughRef));

      let temp_usdearned = await contract
        .getTotalUSDTEarned(connectedAddress.toString())
        .call();
      console.log("total Usd Earned: " + temp_usdearned);
      settotalUsdEarned(tronWeb?.fromSun(parseInt(temp_usdearned)));

      let tempbalance = await contract
        .balanceOf(connectedAddress?.toString())
        .call();
      setuserBalance(parseInt(tempbalance));
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    if (address) {
      setisWalletConnected(true);
      setconnectedAddress(address);
      fetchData(address);
    }
    if (address == null) {
      setisWalletConnected(false);
      setconnectedAddress("isb");
    }
  }, [address]);

  var testDud = "sale.platfair.ai" + "/ref/" + connectedAddress;
  var testSarah= userBalance > 0 ;
  return (
    <div className="content d-flex flex-column min-vh-100">
      <div className="background">
        {/* <Header /> */}

        <section
          id="main"
          className=" flex-grow-1"
          style={{
            color: "white",
            paddingTop: "0px",
          }}
        >
          {isWalletConnected ? (
            <>
              <h1 className="title_dashboard">My Statistics</h1>


              <div className="so_telefone">
                To find your Referral Link, you need to access the browser within your TronLink Wallet. <br></br>
                <div className="d-flex bd-highlight justify-content-center">
                <a href ="https://apps.apple.com/us/app/tronlink-trx-btt-wallet/id1453530188" target="_blank" ><img src={Appstorelogo} className="bot_tel p-2 flex-fill bd-highlight"></img></a>
                <a href = "https://play.google.com/store/apps/details?id=com.tronlinkpro.wallet&hl=es&gl=US" target="_blank"><img src={Playstorelogo} className="bot_tel p-2 flex-fill bd-highlight"></img></a>
                </div></div>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: "70vh" }}
              >



                <div className="box_dashboard d-flex flex-column justify-content-left align-items-left">
                  {testSarah  ? (
                    <>
                      <h1 className="title_buttons_dashboard1">
                        Use this Referral Link to earn up to 10% Comission on
                        Platfair NFT sales:
                      </h1>
                      <h1 className="text_buttons_dashboard1">
                        sale.platfair.ai/ref/{connectedAddress}
                        <button
                          onClick={() => navigator.clipboard.writeText(testDud)}
                          className="buttoncopy"
                        >
                          <svg onClick={() => showNotification(`Referral Link Copied`, "success")}
                            width="20"
                            height="20"
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="13"
                              height="13"
                              x="9"
                              y="9"
                              rx="2"
                              ry="2"
                            ></rect>
                            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                          </svg>{" "}
                        </button>
                      </h1>
                    </>
                  ) : (
                    <div>
                      <h1 className="title_buttons_dashboard">You need at least 1 NFT to be able to see your Referral Link</h1>
                    </div>
                  )}{" "}

                  <h1 className="title_buttons_dashboard">
                      Connected Wallet
                  </h1>
                  <div className="connected_wallet_dashboard text_buttons_dashboard">
                    <FontAwesomeIcon icon={faWallet} />
                    <span className="ps-2">{connectedAddress}</span>
                  </div>
                  
                  <h1 className="title_buttons_dashboard">
                      Total USDT Earned through Referral
                    {/* <span className="sec-colorido">ed</span> */}
                  </h1>
                  <button className="button_dashboard d-flex ng-star-inserted">
                    <FontAwesomeIcon icon={faDollarSign} className="mr-2" />
                    <span className="texto_but_dashb">{totalUsdEarned} </span>
                  </button>

                  <h1 className="title_buttons_dashboard">
                      Total NFTs Minted through Referral
                    {/* <span className="sec-colorido">ed</span> */}
                  </h1>

                  <button className="button_dashboard d-flex ng-star-inserted">
                    <FontAwesomeIcon icon={faGlobe} className="mr-2" />
                    <span className="texto_but_dashb">
                      {totalNftMintedThroughReferral}{" "}
                    </span>
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "70vh" }}
            >
              <h1 className="text-center text-sm">Connect Your Wallet</h1>
            </div>
          )}{" "}
        </section>
      </div>
    </div>
  );
}
